import React from 'react';
import './footer.scss';

const Footer = () => {
    return (
        <footer>
            <span>&copy; 2022 All Rights reserved</span>
        </footer>
    )
}
export default Footer